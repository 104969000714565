import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions, errorSelectors } from '@ducks/error';
import { modalsActions } from '@ducks/modals';
import modals from '@src/utils/constants/modals';
import types from './types';

const getWaistPending = () => ({
  type: types.GET_WAIST_PENDING
});

const getWaistFulfilled = response => ({
  type: types.GET_WAIST_FULFILLED,
  response
});

const getWaistRejected = () => ({
  type: types.GET_WAIST_REJECTED
});

function getWaist(date) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(getWaistFulfilled(response));
    const rejected = error => {
      dispatch(getWaistRejected());
      if (errorSelectors.statusLens(error) !== 404) {
        dispatch(errorActions.errorHandler(error));
      }
    };

    dispatch(getWaistPending());

    return wsCalls().getWaist(fulfilled, rejected, getUserId(), date);
  };
}

const postWaistPending = () => ({
  type: types.POST_WAIST_PENDING
});

const postWaistFulfilled = () => ({
  type: types.POST_WAIST_FULFILLED
});

const postWaistRejected = () => ({
  type: types.POST_WAIST_REJECTED
});

function postWaist(payload, date) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => dispatch(postWaistFulfilled());
    const rejected = error => {
      dispatch(postWaistRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(postWaistPending());

    return wsCalls().postWaist(fulfilled, rejected, payload, getUserId(), date);
  };
}

function postWaistAndUpdateDashboard(payload, date, modalProps) {
  return dispatch =>
    dispatch(postWaist(payload, date)).then(() => {
      dispatch(modalsActions.hideModal(modals.LOG_WAIST_MODAL));
      dispatch(modalsActions.showModal(modals.CONFIRMATION_MODAL, modalProps));
      dispatch(getWaist(date));
    });
}

export default {
  getWaist,
  postWaistAndUpdateDashboard
};
