import types from './types';
import initialState from './initialState';

const weightReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WEIGHT_PENDING: {
      return { ...state, getPending: true };
    }
    case types.GET_WEIGHT_FULFILLED: {
      return {
        ...state,
        getPending: false,
        weightItem: action.response
      };
    }
    case types.GET_WEIGHT_REJECTED: {
      return { ...state, getPending: false };
    }
    case types.POST_WEIGHT_PENDING: {
      return { ...state, postPending: true };
    }
    case types.POST_WEIGHT_FULFILLED: {
      return {
        ...state,
        postPending: false
      };
    }
    case types.POST_WEIGHT_REJECTED: {
      return { ...state, postPending: false };
    }
    default:
      return state;
  }
};

export default weightReducer;
