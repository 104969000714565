import { getUserId } from '@src/utils/helpers/authentication';
import {
  postWeightplan as postWeightplanToApi,
  postFastDays as postFastDaysToApi
} from '@src/utils/helpers/wsCalls';
import { errorActions } from '@ducks/error';
import { modalsActions } from '@ducks/modals';
import { weightplanActions } from '@ducks/weightplan';
import { partialGoalActions } from '@ducks/partialGoal';
import modals from '@src/utils/constants/modals';
import tracking from '@src/utils/helpers/tracking/tracking';
import types from './types';

const getWeightplanPending = () => ({
  type: types.GET_WEIGHTPLAN_PENDING
});

const getWeightplanFulfilled = response => ({
  type: types.GET_WEIGHTPLAN_FULFILLED,
  response
});

const getWeightplanRejected = () => ({
  type: types.GET_WEIGHTPLAN_REJECTED
});

function getWeightplan(isProgramStartPage = false) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(getWeightplanFulfilled(response));
    const rejected = error => {
      dispatch(getWeightplanRejected());
      dispatch(errorActions.errorHandler(error, isProgramStartPage));
    };

    dispatch(getWeightplanPending());

    return wsCalls().getWeightplan(fulfilled, rejected, getUserId());
  };
}

const postWeightplanPending = () => ({
  type: types.POST_WEIGHTPLAN_PENDING
});

const postWeightplanFulfilled = response => ({
  type: types.POST_WEIGHTPLAN_FULFILLED,
  response
});

const postWeightplanRejected = () => ({
  type: types.POST_WEIGHTPLAN_REJECTED
});

function postWeightplan(payload, callback) {
  return dispatch => {
    const fulfilled = response => {
      callback(payload);
      dispatch(postWeightplanFulfilled(response));
    };

    const rejected = error => {
      dispatch(postWeightplanRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(postWeightplanPending());

    return postWeightplanToApi(fulfilled, rejected, payload, getUserId());
  };
}

const postFastDaysPending = () => ({
  type: types.POST_FAST_DAYS_PENDING
});

const postFastDaysFulfilled = response => ({
  type: types.POST_FAST_DAYS_FULFILLED,
  response
});

const postFastDaysRejected = () => ({
  type: types.POST_FAST_DAYS_REJECTED
});

function postFastDays(payload) {
  return dispatch => {
    const fulfilled = response => dispatch(postFastDaysFulfilled(response));

    const rejected = error => {
      dispatch(postFastDaysRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(postFastDaysPending());

    return postFastDaysToApi(fulfilled, rejected, payload, getUserId());
  };
}

function postFastDaysAndUpdateView(payload) {
  return dispatch =>
    dispatch(postFastDays(payload)).then(() => {
      tracking.trackFastingDaysEdited(payload);
      updateProgramView(dispatch);
      dispatch(modalsActions.hideModal(modals.EDIT_FAST_DAYS_MODAL_ID));
    });
}

function updateProgramView(dispatch) {
  dispatch(weightplanActions.getWeightplan());
  dispatch(partialGoalActions.getPartialGoal());
}

export default {
  getWeightplan,
  postWeightplan,
  postFastDaysAndUpdateView
};
