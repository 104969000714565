export default {
  GET_WEIGHTPLAN_PENDING: 'weightplan/GET_WEIGHTPLAN_PENDING',
  GET_WEIGHTPLAN_FULFILLED: 'weightplan/GET_WEIGHTPLAN_FULFILLED',
  GET_WEIGHTPLAN_REJECTED: 'weightplan/GET_WEIGHTPLAN_REJECTED',
  POST_WEIGHTPLAN_PENDING: 'weightplan/POST_WEIGHTPLAN_PENDING',
  POST_WEIGHTPLAN_FULFILLED: 'weightplan/POST_WEIGHTPLAN_FULFILLED',
  POST_WEIGHTPLAN_REJECTED: 'weightplan/POST_WEIGHTPLAN_REJECTED',
  POST_FAST_DAYS_PENDING: 'weightplan/POST_FAST_DAYS_PENDING',
  POST_FAST_DAYS_FULFILLED: 'weightplan/POST_FAST_DAYS_FULFILLED',
  POST_FAST_DAYS_REJECTED: 'weightplan/POST_FAST_DAYS_REJECTED'
};
