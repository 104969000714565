import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions } from '@ducks/error';
import types from './types';

const userRecipesFulfilled = response => ({
  type: types.GET_FULFILLED,
  response
});

const userRecipesRejected = () => ({
  type: types.GET_REJECTED
});
const userRecipesPending = () => ({
  type: types.GET_PENDING
});

function getUserRecipes(max) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(userRecipesFulfilled(response));
    const rejected = error => {
      dispatch(userRecipesRejected());
      dispatch(errorActions.errorHandler(error));
    };
    dispatch(userRecipesPending());

    return wsCalls().getUserRecipes(fulfilled, rejected, getUserId(), max);
  };
}

export default {
  getUserRecipes
};
