import types from './types';
import initialState from './initialState';

const waistReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WAIST_PENDING: {
      return { ...state, getPending: true };
    }
    case types.GET_WAIST_FULFILLED: {
      return {
        ...state,
        getPending: false,
        waistItem: action.response
      };
    }
    case types.GET_WAIST_REJECTED: {
      return { ...state, getPending: false };
    }
    case types.POST_WAIST_PENDING: {
      return { ...state, postPending: true };
    }
    case types.POST_WAIST_FULFILLED: {
      return {
        ...state,
        postPending: false
      };
    }
    case types.POST_WAIST_REJECTED: {
      return { ...state, postPending: false };
    }
    default:
      return state;
  }
};

export default waistReducer;
