"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const useRedux_1 = require("./useRedux");
const useCanTrackHealthData = () => (0, useRedux_1.useAppSelector)(state => {
    if (state.user.productImprovementConsentShouldAsk ||
        state.user.productImprovementConsentStatus === undefined) {
        return undefined;
    }
    if (state.user.productImprovementConsentStatus === 'CONSENTED') {
        return true;
    }
    return false;
});
exports.default = useCanTrackHealthData;
