import * as braze from '@braze/web-sdk';

const SPID_ID = 'sdrn:schibsted.com';
const SPID_ID_NO_PROD = 'sdrn:spid.no';
let spidEnv;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let init = _userId => {};

const setupInit = ({ key, baseUrl, enableLogging, env }) => {
  spidEnv = env;
  braze.disableSDK();
  init = userId => {
    braze.enableSDK();
    braze.initialize(key, {
      baseUrl,
      enableLogging,
      sessionTimeoutInSeconds: 10,
      allowUserSuppliedJavascript: true
    });
    braze.automaticallyShowInAppMessages();
    braze.openSession();
    braze.changeUser(getSpidUser(userId));
  };
};

const getSpidUser = userId => {
  const VEKTKLUBB_PRO_ENV = 'PRO_NO';
  let id = SPID_ID;

  if (spidEnv === VEKTKLUBB_PRO_ENV) {
    id = SPID_ID_NO_PROD;
  }

  return `${id}:user:${userId}`;
};

const changeUser = userId => {
  if (!userId) {
    braze.disableSDK();
    return;
  }
  init(userId);
};

export default {
  setupInit,
  getSpidUser,
  changeUser
};
