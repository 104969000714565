import get from 'lodash/get';
import last from 'lodash/last';
import isEmpty from 'lodash/isEmpty';

const birthdateLens = user => get(user, ['birthdate']);
const maleLens = user => get(user, ['male']);
const latestWeightKiloLens = user => get(user, ['latestWeightKilo']);
const latestWaistCentimeterLens = user => get(user, ['latestWaistCentimeter']);
const heightLens = user => get(user, ['userAttributes', 'height']);
const keepWeightLens = program => get(program, ['keepWeight']);
const pacePerWeekInKilosLens = program => get(program, ['pacePerWeekInKilos']);
const keepWeightKcalPerDayLens = program =>
  get(program, ['keepWeightKcalPerDay']);
const loseWeightKcalPerDayLens = program =>
  get(program, ['loseWeightKcalPerDay']);
const planStartWeightLens = plan => get(plan, ['startWeight']);
const programStartWeightLens = program => get(program, ['startWeight']);
const desiredKeepWeightWarningKgLens = program =>
  get(program, ['desiredKeepWeightWarningKg']);
const desiredWeightLens = plan => get(plan, ['desiredWeight']);
const desiredWeightLossLens = program => get(program, ['desiredWeightLoss']);
const programHeightLens = program => get(program, ['height']);
const programStartWaistLens = program => get(program, ['startWaist']);
const planStartWaistLens = plan => get(plan, ['startWaist']);
const planDateAchievedLens = plan => get(plan, ['dateAchieved']);
const calRestrictedKcalPerDayLens = program =>
  get(program, ['calRestrictedKcalPerDay']);
const weightPlanStartDateLens = plan => get(plan, ['weightPlanStartDate']);
const weightProgramStartDateLens = program =>
  get(program, ['weightProgramStartDate']);

const getHasPlan = plan => !isEmpty(plan);

const getHasUser = user => !isEmpty(user);

const getHasProgram = program => !isEmpty(program);

const getHasWeightPlan = (user, program) =>
  getHasUser(user) && getHasProgram(program);

const getWeightStartDate = (plan, program) =>
  getHasPlan(plan)
    ? weightPlanStartDateLens(plan)
    : weightProgramStartDateLens(program);

const getDefaultCalRestrictedDays = program =>
  program.calRestrictedDaysStarts &&
  last(program.calRestrictedDaysStarts).calRestrictedDays;

const getMyProgram = (program, plan, user) => {
  const hasPlan = getHasPlan(plan);
  const startWeight = hasPlan
    ? planStartWeightLens(plan)
    : programStartWeightLens(program);
  const latestWeightKilo = latestWeightKiloLens(user);
  const isKeepWeight = keepWeightLens(program);
  const defaultCalRestrictedDays = getDefaultCalRestrictedDays(program);

  return {
    hasPlan,
    isKeepWeight,
    kcalPerDay: isKeepWeight
      ? Math.round(keepWeightKcalPerDayLens(program))
      : Math.round(loseWeightKcalPerDayLens(program)),
    startWeight,
    latestWeightKilo,
    weightChange: Math.round((latestWeightKilo - startWeight) * 10) / 10,
    desiredKeepWeightWarningKg: desiredKeepWeightWarningKgLens(program),
    desiredWeight: desiredWeightLens(plan),
    desiredWeightLoss: desiredWeightLossLens(program),
    height: programHeightLens(program),
    startWaist: hasPlan
      ? planStartWaistLens(plan)
      : programStartWaistLens(program),
    hasFiveTwoProgram: !isEmpty(defaultCalRestrictedDays),
    defaultCalRestrictedDays,
    calRestrictedKcalPerDay: calRestrictedKcalPerDayLens(program)
  };
};

export default {
  keepWeightLens,
  birthdateLens,
  maleLens,
  latestWeightKiloLens,
  latestWaistCentimeterLens,
  heightLens,
  pacePerWeekInKilosLens,
  getDefaultCalRestrictedDays,
  getMyProgram,
  getWeightStartDate,
  getHasWeightPlan,
  planDateAchievedLens
};
