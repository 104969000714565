import { getUserId } from '@src/utils/helpers/authentication';
import { postLogin, postLogout } from '@src/utils/helpers/wsCalls';
import localize from '@src/i18n';
import localStorage from '@src/utils/helpers/localStorage';
import firstLetterToUppercase from '@src/utils/helpers/string';
import { getUser, logoutUser } from './schibsted-account';

const loginToApi = async sig => {
  try {
    await new Promise((resolve, reject) => {
      postLogin(resolve, reject, sig);
    });
    return {
      isLoggedIn: true,
      hasSubscription: true
    };
  } catch (err) {
    return {
      isLoggedIn: err.response?.status === 403,
      hasSubscription: false
    };
  }
};

const getUserFromSpidAndLoginToApi = async (isSecondTry = false) => {
  try {
    const spidUserData = await getUser();
    const { isLoggedIn, hasSubscription } = await loginToApi(spidUserData?.sig);
    return {
      userData: spidUserData,
      vkUserId: getUserId(),
      isLoggedIn,
      hasSubscription
    };
  } catch {
    if (isSecondTry) return null;
    localStorage.removeFromStorage('hasSession-cache');
    return getUserFromSpidAndLoginToApi(true);
  }
};

const authenticateUser = async () => {
  let user = await getUserFromSpidAndLoginToApi();

  if (!user && getUserId()) {
    // if we still don't have the user, we still might have a vk session cause we have a vkUserId in the cookies (e.g. in native apps)
    try {
      user = await getUserFromApi();
    } catch {
      // something unexpected happened, do nothing
    }
  }

  return (
    user || {
      userData: {},
      isLoggedIn: false,
      hasSubscription: false
    }
  );
};

/**
 * This function will be used when user wants to logout from system
 * First try to log him from ship-api and on success run logout from schibsted-account which should perform redirect
 */
const clearSession = () => {
  const fulfilled = logoutUser;
  const rejected = () => undefined;

  postLogout(fulfilled, rejected);
};

const getUserFromApi = async () => {
  const response = await fetch(`/api/v5/userids`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include'
  });
  const hasSubscription = response.status === 200 || response.status === 449;
  if (hasSubscription) {
    const { spidId, vkUserId } = await response.json();
    return {
      userData: {
        userId: spidId,
        displayName: firstLetterToUppercase(localize('label_my_profile'))
      },
      vkUserId,
      hasSubscription: true,
      isLoggedIn: true
    };
  }
  const isLoggedIn = hasSubscription || response.status === 403;
  return {
    userData: {},
    hasSubscription: false,
    isLoggedIn
  };
};

export { authenticateUser, clearSession };
