import { useNavigate } from 'react-router-dom';

import { DEFAULT_PRODUCT } from '@src/utils/helpers/isWellobe';
import config from '../config';
import paths from '../pages/paths';

const { open, app, appBase, adminBase, admin, privacySettings } =
  paths[config.product || DEFAULT_PRODUCT];

const ROOT = '/';
const APP_BASE = `${appBase}/`;

const logbookUrl = APP_BASE + app.logbook;
const startProgramUrl = APP_BASE + app.programStart;
const currentProgramUrl = APP_BASE + app.programCurrent;
const noActiveSubUrl = APP_BASE + app.noActiveSubscription;
const recipeBaseUrl = APP_BASE + app.recipes;
const recipeSharedUrl = (recipeId, hash) =>
  `${config.schibstedAccount.redirectUri}${APP_BASE}${app.recipes}/${recipeId}${
    hash
      ? `?shareToken=${hash}&utm_source=shared-recipe&utm_content=user-recipe`
      : '?utm_source=shared-recipe&utm_content=system-recipe'
  }`;
const userRecipesBaseUrl = APP_BASE + app.userRecipes;
const favouriteRecipesBaseUrl = APP_BASE + app.favouriteRecipes;

const recipeCreateBaseUrl = `${APP_BASE + app.recipes}/${app.edit}`;
const foodstuffCreateBaseUrl = `${APP_BASE + app.foodstuff}/${app.edit}`;
const healthConsentUrl = APP_BASE + app.healthConsent;
const welcomeToUrl = APP_BASE + app.welcomeTo;
const menuUrl = APP_BASE + app.menu;
const menuOnboardingUrl = APP_BASE + app.menuOnboarding;
const menuOnboardingWelcomeUrl = APP_BASE + app.menuOnboardingWelcome;

const buySubscriptionUrl = ROOT + open.subscription;
const aboutOurServiceUrl = ROOT + open.about;
const campaignTestingUrl = ROOT + open.campaignTesting;
const bmiUrl = ROOT + open.bmi;
const inspirationSectionUrl = ROOT + open.inspiration;
const expertsSectionUrl = ROOT + open.experts;
const successStoriesSectionUrl = ROOT + open.successStories;
const membercaseSectionUrl = ROOT + open.membercase;
const dealsSectionUrl = ROOT + open.deals;
const articlesListUrl = `${open.inspiration}/:sectionId`;
const articleSectionUrl = `${open.inspiration}/:sectionId/:articleListId`;
const expertPageUrl = `${ROOT}${open.experts}/:expertName`;
const sitemap = ROOT + open.sitemap;
const articleSearchUrl = ROOT + open.searchResults;
const supportUrl = ROOT + open.support;

const vektklubbGeneralArticleUrl = `${ROOT}${open.inspiration}/:sectionId/:subsectionId/:articleId/:title`;
const wellobeGeneralArticleUrl = `${ROOT}${open.inspiration}/:sectionId/:articleId/:title`;
const expertArticleUrl = `${ROOT}${open.experts}/:expertName/:articleId/:title`;
const successStoriesArticleUrl = `${ROOT}${open.successStories}/:articleId/:title`;
const membercaseArticleUrl = `${ROOT}${open.membercase}/:articleId/:title`;
const dealsArticleUrl = `${ROOT}${open.deals}/:articleId/:title`;
const uncategorizedArticleUrl = `${ROOT}${open.inspiration}/${open.uncategorizedArticle}/:articleId/:title`;

const editFoodstuffByAdminUrl = `${adminBase}/${admin.foodstuff}`;
const seeMenuByAdminUrl = `${adminBase}/${admin.menu}`;
const mergePendingFoodstuffByAdminUrl = `${adminBase}/${admin.foodstuffMerging}`;

const useCustomNavigate = () => {
  const navigate = useNavigate();

  const navigateTo = (href, options) => navigate(href, options);
  const navigateToCurrentProgram = () => navigate(currentProgramUrl);
  const navigateToProgramStart = () => navigate(startProgramUrl);
  const navigateToAboutService = () => navigate(aboutOurServiceUrl);
  const navigateToLoginPage = () => navigate(ROOT);
  const navigateToHomePage = () => navigate(ROOT);
  const navigateToLogbook = () => navigate(logbookUrl);
  const navigateToLogbookWithParams = params =>
    navigate(`${logbookUrl}?${params}`);
  const navigateToSearchWithParams = params =>
    navigate(`${articleSearchUrl}?${params}`);
  const navigateToFoodstuffWithParams = (id, params, state) =>
    navigate(`${foodstuffCreateBaseUrl}${id ? `/${id}` : ''}${params}`, {
      state
    });
  const navigateToRecipeCreate = (trackingOrigin, id, isEditMode) =>
    navigate(
      `${recipeCreateBaseUrl}${id ? `/${id}` : ''}${
        isEditMode ? '?edit=true' : ''
      }`,
      { state: { trackingOrigin } }
    );
  const navigateToRecipeBaseWithParams = params =>
    navigate(`${recipeBaseUrl}?${params}`, { replace: true });
  const navigateToRecipeBase = () => navigate(recipeBaseUrl);
  const navigateToRecipe = id => navigate(`${recipeBaseUrl}/${id}`);
  const navigateToRecipeWithParams = (id, params, state) =>
    navigate(`${recipeBaseUrl}/${id}?${params}`, { state });
  const navigateToBuySubscriptionPage = state =>
    navigate(buySubscriptionUrl, { state });
  const navigateToHealthConsent = () => navigate(healthConsentUrl);
  const navigateToMenu = trackingOrigin =>
    navigate(menuUrl, { state: { trackingOrigin } });
  const navigateToMenuOnboarding = currentComponent =>
    navigate(menuOnboardingUrl, { state: { currentComponent } });
  const navigateToMenuOnboardingWelcome = () =>
    navigate(menuOnboardingWelcomeUrl);
  const navigateToInspirationSection = section =>
    navigate(`${inspirationSectionUrl}/${section}`);
  const navigateBack = () => navigate(-1);

  return {
    navigateTo,
    navigateToCurrentProgram,
    navigateToProgramStart,
    navigateToAboutService,
    navigateToLoginPage,
    navigateToHomePage,
    navigateToLogbook,
    navigateToLogbookWithParams,
    navigateToSearchWithParams,
    navigateToFoodstuffWithParams,
    navigateToRecipeCreate,
    navigateToRecipeBaseWithParams,
    navigateToRecipeBase,
    navigateToRecipe,
    navigateToRecipeWithParams,
    navigateToBuySubscriptionPage,
    navigateToHealthConsent,
    navigateToMenuOnboarding,
    navigateToMenuOnboardingWelcome,
    navigateToMenu,
    navigateToInspirationSection,
    navigateBack
  };
};

export {
  ROOT as rootUrl,
  appBase as appUrl,
  adminBase as adminUrl,
  privacySettings as privacySettingsUrl,
  logbookUrl,
  noActiveSubUrl,
  buySubscriptionUrl,
  bmiUrl,
  aboutOurServiceUrl,
  campaignTestingUrl,
  vektklubbGeneralArticleUrl,
  wellobeGeneralArticleUrl,
  expertArticleUrl,
  successStoriesArticleUrl,
  membercaseArticleUrl,
  dealsArticleUrl,
  uncategorizedArticleUrl,
  articlesListUrl,
  articleSectionUrl,
  inspirationSectionUrl,
  expertsSectionUrl,
  expertPageUrl,
  successStoriesSectionUrl,
  dealsSectionUrl,
  membercaseSectionUrl,
  startProgramUrl,
  currentProgramUrl,
  recipeBaseUrl,
  recipeSharedUrl,
  recipeCreateBaseUrl,
  foodstuffCreateBaseUrl,
  editFoodstuffByAdminUrl,
  seeMenuByAdminUrl,
  mergePendingFoodstuffByAdminUrl,
  welcomeToUrl,
  sitemap,
  healthConsentUrl,
  menuUrl,
  userRecipesBaseUrl,
  favouriteRecipesBaseUrl,
  articleSearchUrl,
  supportUrl
};

export default useCustomNavigate;
