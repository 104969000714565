import { getUserId } from '@src/utils/helpers/authentication';
import { weightplanActions } from '@ducks/weightplan';
import { errorActions } from '@ducks/error';
import { authenticateUser } from '@src/helpers/authentication';
import { userMenuFulfilled, userMenuRejected } from '@ducks/navbar/actions';
import types from './types';

const getInProgress = () => ({
  type: types.GET_CONSENT_IN_PROGRESS
});

const getFulfilled = response => ({
  type: types.GET_CONSENT_FULFILLED,
  response
});

const getRejected = () => ({
  type: types.GET_CONSENT_REJECTED
});

const getRightsFulfilled = response => {
  const isAdmin = response.rights.includes('EDIT_SYSTEM_FOOD');
  const isMenuAdmin = response.rights.includes('EDIT_SYSTEM_MENUS');
  return {
    type: types.GET_USER_RIGHTS_FULFILLED,
    isAdmin,
    isMenuAdmin
  };
};

const getRightsRejected = error => ({
  type: types.GET_USER_RIGHTS_REJECTED,
  error: {
    message: error.message
  }
});

function getRights(vkUserId) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(getRightsFulfilled(response));
    const rejected = error => dispatch(getRightsRejected(error));
    if (vkUserId) {
      return wsCalls().getCsRights(fulfilled, rejected, vkUserId);
    }
    return Promise.reject(
      new Error('can not fetch rights with unknown user id')
    );
  };
}

const userLoaded = response => ({
  type: types.USER_DATA_LOADED,
  response
});

const userCleared = () => ({
  type: types.USER_DATA_CLEARED
});

const setActiveUser = data => dispatch => dispatch(userLoaded(data));

const clearUser = () => dispatch => {
  dispatch(userCleared());
};

const userStateFulFilled = response => ({
  type: types.GET_USER_STATE_FULFILLED,
  response
});

const getUserState = () => async dispatch => {
  const user = await authenticateUser();

  const {
    isLoggedIn = false,
    vkUserId,
    hasSubscription = false,
    userData
  } = user || {};
  if (isLoggedIn && hasSubscription) {
    if (vkUserId) dispatch(getRights(vkUserId));
    dispatch(userMenuFulfilled(userData));
  } else {
    dispatch(userMenuRejected({}));
  }
  dispatch(userStateFulFilled(user));
};

const getWeightplan = () => dispatch => {
  dispatch(weightplanActions.getWeightplan());
};

function getHealthDataConsent() {
  return (dispatch, _, wsCalls) => {
    const rejected = error => {
      dispatch(getRejected());
      dispatch(errorActions.errorHandler(error));
    };

    const fulfilled = response => {
      dispatch(getFulfilled(response));
    };

    dispatch(getInProgress());

    return wsCalls().getHealthDataConsent(fulfilled, rejected, getUserId());
  };
}

export default {
  setActiveUser,
  clearUser,
  getRights,
  getUserState,
  getWeightplan,
  getHealthDataConsent
};
