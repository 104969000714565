import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions, errorSelectors } from '@ducks/error';
import { modalsActions } from '@ducks/modals';
import modals from '@src/utils/constants/modals';
import types from './types';

const getWeightPending = () => ({
  type: types.GET_WEIGHT_PENDING
});

const getWeightFulfilled = response => ({
  type: types.GET_WEIGHT_FULFILLED,
  response
});

const getWeightRejected = () => ({
  type: types.GET_WEIGHT_REJECTED
});

function getWeight(date) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(getWeightFulfilled(response));
    const rejected = error => {
      dispatch(getWeightRejected());
      if (errorSelectors.statusLens(error) !== 404) {
        dispatch(errorActions.errorHandler(error));
      }
    };

    dispatch(getWeightPending());

    return wsCalls().getWeight(fulfilled, rejected, getUserId(), date);
  };
}

const postWeightPending = () => ({
  type: types.POST_WEIGHT_PENDING
});

const postWeightFulfilled = () => ({
  type: types.POST_WEIGHT_FULFILLED
});

const postWeightRejected = () => ({
  type: types.POST_WEIGHT_REJECTED
});

const getNotificationsFulfilled = notifications => ({
  type: 'notifications/GET_NOTIFICATIONS_FULFILLED',
  notifications
});

function postWeight(payload, date) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => {
      dispatch(postWeightFulfilled());
      const { notifications = [] } = response;
      dispatch(getNotificationsFulfilled(notifications));
    };
    const rejected = error => {
      dispatch(postWeightRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(postWeightPending());

    return wsCalls().postWeight(
      fulfilled,
      rejected,
      payload,
      getUserId(),
      date
    );
  };
}

function postWeightAndUpdateDashboard(payload, date, modalProps) {
  return dispatch =>
    dispatch(postWeight(payload, date)).then(() => {
      dispatch(modalsActions.hideModal(modals.LOG_WEIGHT_MODAL));
      dispatch(modalsActions.showModal(modals.CONFIRMATION_MODAL, modalProps));
      dispatch(getWeight(date));
    });
}

export default {
  getWeight,
  postWeightAndUpdateDashboard
};
