import requestStatus from '@src/utils/helpers/requestStatus';
import types from './types';
import initialState from './initialState';

const switchMeal = (
  menu,
  switchedMealDate,
  mealType,
  mealIndex,
  { foodItemGroupId: id }
) =>
  menu.map(day =>
    switchedMealDate === day.date
      ? {
          ...day,
          meals: day.meals.map((meal, i) =>
            meal.name === mealType && i === mealIndex
              ? {
                  ...meal,
                  foodItemGroups: meal.foodItemGroups.map(obj =>
                    obj.foodItemGroupId === id
                      ? { ...obj, selected: true }
                      : { ...obj, selected: false }
                  )
                }
              : meal
          )
        }
      : day
  );

const weeklyMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WEEKLY_MENU_PENDING: {
      return {
        ...state,
        getStatus: requestStatus.pending
      };
    }
    case types.GET_WEEKLY_MENU_FULFILLED: {
      return {
        ...state,
        menu: action.response.menu,
        getStatus: requestStatus.fulfilled
      };
    }
    case types.GET_WEEKLY_MENU_REJECTED: {
      return {
        ...state,
        getStatus: requestStatus.rejected,
        error: {
          message: action.error.message
        }
      };
    }
    case types.SWITCH_MEAL_PENDING: {
      return {
        ...state,
        switchMealStatus: requestStatus.pending
      };
    }
    case types.SWITCH_MEAL_FULFILLED: {
      return {
        ...state,
        menu: switchMeal(
          state.menu,
          action.date,
          action.mealType,
          action.mealIndex,
          action.payload
        ),
        switchMealStatus: requestStatus.fulfilled
      };
    }
    case types.SWITCH_MEAL_REJECTED: {
      return {
        ...state,
        switchMealStatus: requestStatus.rejected,
        error: {
          message: action.error.message
        }
      };
    }
    default:
      return state;
  }
};

export default weeklyMenuReducer;
