// This module is used for tracking from where the user came from before visiting
// SHIP. This information is later used in the purchase links on the blimedlem widgets.
import isEqual from 'lodash/isEqual';
import localStorage from '../utils/helpers/localStorage';
import getParams from './campaignParams';

const constants = {
  REFERRING_HOST: 'referring_host',
  LANDING: 'landing_url',
  STORAGE_ID: 'referrerTracking',
  ARTICLE_ID: 'article_id',
  ARTICLE_CANONICAL_URL: 'article_canonical_url',
  STORAGE_EXPIRATION_IN_MINUTES: 60 * 24 * 1 // 24 hours
};

const getRefs = storage => storage.getFromStorage(constants.STORAGE_ID);

const getValueFromStorage = (storage, key) => {
  if (!storage || !key) return null;
  const refs = getRefs(storage);
  return refs && refs[key];
};

const getLandingUrl = storage => {
  const landingUrl = getValueFromStorage(storage, constants.LANDING);
  try {
    return new URL(landingUrl);
  } catch {
    return null;
  }
};

const setRefs = (refs, storage) => {
  if (!storage) return;
  storage.addToStorage(
    constants.STORAGE_ID,
    refs,
    constants.STORAGE_EXPIRATION_IN_MINUTES
  );
};

const getHref = location => {
  const {
    href,
    pathname,
    search,
    origin = `${document.location.protocol}//${document.location.host}`
  } = location;
  return href || `${origin}${pathname}${search}`;
};

const updateRefsInStorage = (
  location,
  allowedReferringHost,
  articleId,
  storage,
  articleCanonicalUrl
) => {
  if (!(location && storage)) return;

  const queryParams = new URLSearchParams(location && location.search);
  const refsFromStorage = getRefs(storage);
  const refs = { ...refsFromStorage } || {};

  if (
    allowedReferringHost &&
    allowedReferringHost !== refs[constants.REFERRING_HOST]
  ) {
    refs[constants.LANDING] = getHref(location);
    refs[constants.REFERRING_HOST] = allowedReferringHost;
  }

  if (queryParams.has('refpartner') || !refs[constants.LANDING]) {
    refs[constants.LANDING] = getHref(location);
  }

  if (articleId) {
    refs[constants.ARTICLE_ID] = articleId;
  }

  if (articleCanonicalUrl) {
    refs[constants.ARTICLE_CANONICAL_URL] = articleCanonicalUrl;
  }

  if (isEqual(refs, refsFromStorage)) {
    return;
  }
  setRefs(refs, storage);
};

const getReferringHost = () => {
  try {
    const referrerUrl = new URL(document.referrer);
    return referrerUrl.host;
  } catch {
    return null;
  }
};

const allowedReferringHost = () => {
  const referringHost = getReferringHost();
  const isInternalDomain =
    document.referrer.indexOf(
      `${document.location.protocol}//${document.location.host}`
    ) === 0;

  const isPaymentOrIdentityReferrer = referringHost
    ? referringHost.indexOf('schibsted.') >= 0
    : false;
  const isAllowedReferrer = !isInternalDomain && !isPaymentOrIdentityReferrer;
  return isAllowedReferrer ? referringHost : null;
};

const getCampaignParams = (articleId, currentlocation, articleCanonicalUrl) => {
  updateRefsInStorage(
    currentlocation,
    allowedReferringHost(),
    articleId,
    localStorage,
    articleCanonicalUrl
  );
  const landingUrl = getLandingUrl(localStorage);
  const referrer = getValueFromStorage(localStorage, constants.REFERRING_HOST);
  return getParams(
    currentlocation,
    landingUrl,
    articleId || getValueFromStorage(localStorage, constants.ARTICLE_ID),
    referrer,
    articleCanonicalUrl ||
      getValueFromStorage(localStorage, constants.ARTICLE_CANONICAL_URL)
  );
};

export default {
  getCampaignParams
};
