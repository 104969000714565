const weekDays = {
  MONDAY: 0,
  TUESDAY: 1,
  WEDNESDAY: 2,
  THURSDAY: 3,
  FRIDAY: 4,
  SATURDAY: 5,
  SUNDAY: 6
};

export default {
  getPending: false,
  postPending: false,
  reminderDay: weekDays.MONDAY,
  weightItem: {}
};
