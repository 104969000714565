/* eslint-disable import/no-named-as-default-member */
import { sv, nb } from 'date-fns/locale';
import moment from 'moment';
import dependencies from '@src/utils/helpers/dependencies';
import { isItWellobe } from '@src/utils/helpers/isWellobe';
import config from '../config';
import { logbookUrl, startProgramUrl, currentProgramUrl } from './navigation';
import { authenticate } from './schibsted-account';
import { clearSession } from './authentication';

const schibstedAccountService = { authenticate, clearSession };
const isWellobe = isItWellobe(config.product);
const svLocale = 'sv';
const noLocale = 'nb';

moment.locale(isWellobe ? svLocale : noLocale);

const registerDependencies = () => {
  dependencies.set(
    dependencies.keys.SCHIBSTED_ACCOUNT,
    schibstedAccountService
  );

  dependencies.set(dependencies.keys.LINKS_LOGBOOK, logbookUrl);
  dependencies.set(dependencies.keys.LINKS_PROGRAM, currentProgramUrl);
  dependencies.set(dependencies.keys.LINKS_PROGRAM_START, startProgramUrl);
  dependencies.set(dependencies.keys.ALLOW_RECIPE_EDIT, false);

  dependencies.set(
    dependencies.keys.DATE_FNS_KEY,
    isWellobe ? svLocale : noLocale
  );
  dependencies.set(dependencies.keys.DATE_FNS_LANG, isWellobe ? sv : nb);

  dependencies.set(dependencies.keys.I18N, {
    i18n: config.i18n
  });

  dependencies.set(dependencies.keys.PRODUCT_ID, config.productId);
  dependencies.set(dependencies.keys.TRACKING_ENABLED, config.trackingEnabled);
  dependencies.set(dependencies.keys.VERSION, config.version);
  dependencies.set(dependencies.keys.PULSE_CONFIG, config.pulseConfig);

  dependencies.set(
    dependencies.keys.CONSENT_API_URL,
    config.consentApiEndpoint
  );
};

export default registerDependencies;
