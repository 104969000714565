import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions } from '@ducks/error';
import types from './types';

const userSettingsFulfilled = response => ({
  type: types.GET_USER_SETTINGS_FULFILLED,
  response
});

const userSettingsRejected = error => ({
  type: types.GET_USER_SETTINGS_REJECTED,
  error: {
    message: error.message
  }
});

const userSettingsPending = () => ({
  type: types.GET_USER_SETTINGS_PENDING
});

const putUserSettingsPending = () => ({
  type: types.PUT_USER_SETTINGS_PENDING
});

const putUserSettingsFulfilled = () => ({
  type: types.PUT_USER_SETTINGS_FULFILLED
});

const putUserSettingsRejected = () => ({
  type: types.PUT_USER_SETTINGS_REJECTED
});

function putUserSettings(payload) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => dispatch(putUserSettingsFulfilled());
    const rejected = error => {
      dispatch(putUserSettingsRejected());
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(putUserSettingsPending());

    return wsCalls().putUserSettings(fulfilled, rejected, payload, getUserId());
  };
}

function getUserSettings() {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(userSettingsFulfilled(response));
    const rejected = error => {
      dispatch(userSettingsRejected(error));
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(userSettingsPending());
    return wsCalls().getUserSettings(fulfilled, rejected, getUserId());
  };
}

export default {
  getUserSettings,
  putUserSettings
};
