import { peter, ulf, ingela, carolina } from './images/wellobe';

export default {
  users: [
    {
      src: carolina,
      title: 'Carolina',
      tag: '-16 kg',
      body: 'Innan jagade jag siffror på vågen. Idag är bättre sömn och mindre värk mina största vinster. Jag mår strålande!'
    },
    {
      src: peter,
      title: 'Peter',
      tag: '-31 kg',
      body: 'Jag har fått blodtryck som en 15-åring, bättre sömn, humör – och trivs framför spegeln. Jag kommer aldrig gå tillbaka!'
    },
    {
      src: ingela,
      title: 'Ingela',
      tag: '-21 kg',
      body: 'Jag behövde skapa en bra balans mellan min mat och motion. När jag väl började äta mer och träna mindre kom resultaten'
    },
    {
      src: ulf,
      title: 'Ulf',
      tag: '-42 kg',
      body: 'Jag höll mig till min kaloriplan och tränade en halvtimme om dagen. Wellobe har räddat mitt liv. Frun och barnen är helt tagna.'
    }
  ],
  href: '/medlemscase',
  cta: 'Läs om fler medlemmar'
};
