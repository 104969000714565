import requestStatus from '@src/utils/helpers/requestStatus';
import types from './types';
import initialState from './initialState';

const weeklyMenuAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WEEKLY_MENU_ADMIN_PENDING: {
      return {
        ...state,
        menu: [],
        getStatus: requestStatus.pending
      };
    }
    case types.GET_WEEKLY_MENU_ADMIN_FULFILLED: {
      return {
        ...state,
        menu: action.response.menu,
        getStatus: requestStatus.fulfilled
      };
    }
    case types.GET_WEEKLY_MENU_ADMIN_REJECTED: {
      return {
        ...state,
        getStatus: requestStatus.rejected,
        error: {
          message: action.error.message
        }
      };
    }
    default:
      return state;
  }
};

export default weeklyMenuAdminReducer;
