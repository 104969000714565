import requestStatus from '@src/utils/helpers/requestStatus';
import types from './types';
import initialState from './initialState';

const weightplanReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_WEIGHTPLAN_PENDING: {
      return { ...state, pending: true };
    }
    case types.GET_WEIGHTPLAN_FULFILLED: {
      return {
        ...state,
        pending: false,
        callCompleted: true,
        user: action.response && action.response.user,
        program: action.response && action.response.program,
        plan: action.response && action.response.plan
      };
    }
    case types.GET_WEIGHTPLAN_REJECTED: {
      return { ...state, pending: false, callCompleted: true };
    }
    case types.POST_WEIGHTPLAN_PENDING: {
      return { ...state, postStatus: requestStatus.pending };
    }
    case types.POST_WEIGHTPLAN_FULFILLED: {
      return {
        ...state,
        postStatus: requestStatus.fulfilled
      };
    }
    case types.POST_WEIGHTPLAN_REJECTED: {
      return { ...state, postStatus: requestStatus.rejected };
    }
    case types.POST_FAST_DAYS_PENDING: {
      return { ...state, postFastDaysPending: true };
    }
    case types.POST_FAST_DAYS_FULFILLED: {
      return {
        ...state,
        postFastDaysPending: false
      };
    }
    case types.POST_FAST_DAYS_REJECTED: {
      return { ...state, postFastDaysPending: false };
    }
    default:
      return state;
  }
};

export default weightplanReducer;
