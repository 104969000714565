import get from 'lodash/get';
import { recipeSearchSelectors } from '@ducks/recipeSearch';

const idLens = item => get(item, ['id']);
const nameLens = item => get(item, ['name']);
const kcalPerPortionLens = item => get(item, ['kcalPerPortion']);
const kcalLens = item => get(item, ['kcal']);

const imageLens = item => get(item, ['image']);
const imageVariantsLens = item => get(item, ['images', '0', 'variants']);
const ratingAverageLens = item => get(item, ['ratingAverage']) || null;
const isFavouriteLens = state => get(state, ['isFavourite']);
const cookingTimeLens = state => get(state, ['cookingTime']);

const getUserRecipes = recipes =>
  recipes.map(item => ({
    id: idLens(item),
    name: nameLens(item),
    amount: 1,
    unitName: 'port',
    kcal: kcalPerPortionLens(item) || kcalLens(item),
    hasImage: !!imageLens(item),
    images: imageVariantsLens(item),
    ratingAverage: ratingAverageLens(item),
    isRecipe: true,
    tags: recipeSearchSelectors.getTags(item),
    isFavourite: isFavouriteLens(item),
    cookingTime: cookingTimeLens(item)
  }));

export default {
  getUserRecipes
};
