import { getUserId } from '@src/utils/helpers/authentication';
import { getUserFoodstuffs as userFoodstuffs } from '@src/utils/helpers/wsCalls';
import { errorActions } from '@ducks/error';
import types from './types';

const userFoodstuffsFulfilled = response => ({
  type: types.GET_FULFILLED,
  response
});

const userFoodstuffsRejected = () => ({
  type: types.GET_REJECTED
});

function getUserFoodstuffs(max) {
  return dispatch => {
    const fulfilled = response => dispatch(userFoodstuffsFulfilled(response));
    const rejected = error => {
      dispatch(userFoodstuffsRejected());
      dispatch(errorActions.errorHandler(error));
    };

    userFoodstuffs(fulfilled, rejected, getUserId(), max);
  };
}

export default {
  getUserFoodstuffs
};
