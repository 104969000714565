import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions } from '@ducks/error';
import types from './types';

const weeklyMenuAdminFulfilled = response => ({
  type: types.GET_WEEKLY_MENU_ADMIN_FULFILLED,
  response
});

const weeklyMenuAdminRejected = error => ({
  type: types.GET_WEEKLY_MENU_ADMIN_REJECTED,
  error: {
    message: error.message
  }
});

const weeklyMenuAdminPending = () => ({
  type: types.GET_WEEKLY_MENU_ADMIN_PENDING
});

function getWeeklyMenuAdmin(date, query) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(weeklyMenuAdminFulfilled(response));
    const rejected = error => {
      dispatch(weeklyMenuAdminRejected(error));
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(weeklyMenuAdminPending());
    return wsCalls().getMenuAdmin(
      fulfilled,
      rejected,
      getUserId(),
      date,
      query
    );
  };
}

export default {
  getWeeklyMenuAdmin
};
