import get from 'lodash/get';

const adminLens = item => get(item, ['isAdmin']);
const vkUserIdLens = item => get(item, ['vkUserId']);

const getUserName = user => {
  let userName = user.displayName;

  if (!userName && user.firstName) {
    if (user.firstName && user.lastName) {
      userName = `${user.firstName} ${user.lastName}`;
    } else {
      userName = '';
    }
  }

  return userName;
};

export default {
  getUserName,
  vkUserIdLens,
  adminLens
};
