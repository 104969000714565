const getParams = (
  currentlocation,
  landingUrl,
  articleId,
  referrer,
  articleCanonicalUrl
) => {
  const normalizedReferrer =
    referrer && referrer.includes('google')
      ? 'google'
      : referrer || 'salespage';
  const params = {
    type: articleId ? 'article' : 'salespage',
    utm_channel: 'web',
    utm_source: normalizedReferrer,
    utm_campaign: 'general',
    utm_term: 'general+salespage',
    utm_content: 'unknown',
    content_id: articleId || 'unknown',
    tag:
      (landingUrl && landingUrl.href) ||
      (currentlocation && currentlocation.origin) ||
      'unknown'
  };

  const landingParams = new URLSearchParams(landingUrl && landingUrl.search);
  const currentlocationParams = new URLSearchParams(
    currentlocation && currentlocation.search
  );

  Array.from(currentlocationParams.entries()).forEach(([key, value]) =>
    // overriding landing url params with current location url params
    landingParams.set(key, value)
  );
  Array.from(landingParams.entries())
    .filter(
      ([key]) =>
        key.startsWith('utm_') ||
        ['type', 'content_id', 'refpartner', 'gclid', 'fbclid'].includes(key)
    )
    .forEach(([name, value]) => {
      if (name === 'utm_medium') {
        params.utm_channel = value;
      } else if (name === 'utm_campaign') {
        params.utm_term = `${params.utm_term}+${value}`;
        params[name] = value;
      } else if (name === 'refpartner') {
        params.utm_content = value;
      } else {
        params[name] = value;
      }
    });

  // utm_content and utm_medium values take priority over refpartner and utm_channel
  Array.from(landingParams.entries())
    .filter(([key]) => ['utm_content', 'utm_medium', 'at_gd'].includes(key))
    .forEach(([name, value]) => {
      if (name === 'utm_medium') {
        params.utm_channel = value;
      } else if (name === 'at_gd') {
        params.utm_term = value;
      } else {
        params[name] = value;
      }
    });

  if (articleCanonicalUrl) {
    params.utm_url = articleCanonicalUrl;
  }
  return params;
};

export default getParams;
