export default {
  displayName: '',
  firstName: '',
  lastName: '',
  userId: '',
  sig: '',
  vkUserId: '',
  isLoggedIn: undefined,
  hasSubscription: undefined,
  productImprovementConsentStatus: undefined,
  productImprovementConsentShouldAsk: undefined,
  isAdmin: undefined,
  isMenuAdmin: undefined
};
