import { getUserId } from '@src/utils/helpers/authentication';
import { errorActions } from '@ducks/error';
import types from './types';

const weeklyMenuFulfilled = response => ({
  type: types.GET_WEEKLY_MENU_FULFILLED,
  response
});

const weeklyMenuRejected = error => ({
  type: types.GET_WEEKLY_MENU_REJECTED,
  error: {
    message: error.message
  }
});

const weeklyMenuPending = () => ({
  type: types.GET_WEEKLY_MENU_PENDING
});

const switchMealFulfilled = (date, mealType, mealIndex, payload) => ({
  type: types.SWITCH_MEAL_FULFILLED,
  date,
  mealType,
  mealIndex,
  payload
});

const switchMealRejected = error => ({
  type: types.SWITCH_MEAL_REJECTED,
  error: {
    message: error.message
  }
});

const switchMealPending = () => ({
  type: types.SWITCH_MEAL_PENDING
});

function getWeeklyMenu(date) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = response => dispatch(weeklyMenuFulfilled(response));
    const rejected = error => {
      dispatch(weeklyMenuRejected(error));
      dispatch(errorActions.errorHandler(error));
    };

    dispatch(weeklyMenuPending());
    return wsCalls().getMenu(fulfilled, rejected, getUserId(), date);
  };
}

function switchMeal(date, mealType, mealIndex, payload) {
  return (dispatch, _, wsCalls) => {
    const fulfilled = () => {
      dispatch(switchMealFulfilled(date, mealType, mealIndex, payload));
    };
    const rejected = error => {
      dispatch(switchMealRejected(error));
      dispatch(errorActions.errorHandler(error));
    };
    dispatch(switchMealPending());
    return wsCalls().postSwitchMeal(
      fulfilled,
      rejected,
      payload,
      getUserId(),
      date,
      mealType
    );
  };
}

export default {
  getWeeklyMenu,
  switchMeal
};
