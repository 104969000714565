import requestStatus from '@src/utils/helpers/requestStatus';
import types from './types';
import initialState from './initialState';

const userSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_SETTINGS_PENDING: {
      return {
        ...state,
        getStatus: requestStatus.pending
      };
    }
    case types.GET_USER_SETTINGS_FULFILLED: {
      return {
        ...state,
        settings: action.response,
        getStatus: requestStatus.fulfilled
      };
    }
    case types.GET_USER_SETTINGS_REJECTED: {
      return {
        ...state,
        getStatus: requestStatus.rejected,
        error: {
          message: action.error.message
        }
      };
    }
    case types.PUT_USER_SETTINGS_PENDING: {
      return { ...state, putStatus: requestStatus.pending };
    }
    case types.PUT_USER_SETTINGS_FULFILLED: {
      return {
        ...state,
        putStatus: requestStatus.fulfilled
      };
    }
    case types.PUT_USER_SETTINGS_REJECTED: {
      return {
        ...state,
        putStatus: requestStatus.rejected,
        error: {
          message: action.error.message
        }
      };
    }
    default:
      return state;
  }
};

export default userSettingsReducer;
