import get from 'lodash/get';

const idLens = item => get(item, ['id']);
const nameLens = item => get(item, ['name']);
const unitNameLens = item => get(item, ['unitName']);
const kcalPerUnitLens = item => get(item, ['kcalPerUnit']);
const imageLens = item => get(item, ['image']);

const getUserFoodstuffs = recipes =>
  recipes.map(item => ({
    id: idLens(item),
    name: nameLens(item),
    amount: 1,
    unitName: unitNameLens(item),
    kcal: kcalPerUnitLens(item),
    hasImage: !!imageLens(item)
  }));

export default {
  getUserFoodstuffs
};
